import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import { useNavigate, useLocation } from "react-router-dom";
import { loginActions } from '../store/login-slice';

const Login = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()


    useEffect(() => {
       
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('code');
        if (code) {
            fetch(`https://${process.env.REACT_APP_AWS_DOMAIN}oauth2/token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: `grant_type=authorization_code&client_id=${process.env.REACT_APP_AWS_CLIENT_ID}&code=${code}&redirect_uri=${encodeURIComponent(process.env.REACT_APP_REDIRECT_URL)}`
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    const access_token = data.access_token;
                    localStorage.setItem('access_token', access_token);
                    dispatch(loginActions.setLogin());
                    navigate('/dashboard')
                    //   history.push('/dashboard')

                })
                .catch(error => console.error('Error:', error));
        }

  

    }, [])
    const handleLoginWithAzure = async (e) => {
        e.preventDefault();

        // const newUser = await Auth.federatedSignIn({ provider: 'ADWITHAWS' });
        // console.log(newUser);
        const redirectUrl = `https://${process.env.REACT_APP_AWS_DOMAIN}/oauth2/authorize?client_id=${process.env.REACT_APP_AWS_CLIENT_ID}&response_type=code&scope=email+openid+phone&redirect_uri=${encodeURIComponent(process.env.REACT_APP_REDIRECT_URL)}`;
        console.log(redirectUrl)
        window.location.replace(redirectUrl);
    }
    const handleAuthentication = async () => {
        //    return   getAuthLink('authenticate').then((url)=>{
        //    return `https://azsttauth.auth.ap-southeast-1.amazoncognito.com/login?client_id=5an7cdv75mu3dl13usljd63mj&response_type=code&scope=email+openid+phone&redirect_uri=${url}`
        //       })
        // let url =  `https://azsttauth.auth.ap-southeast-1.amazoncognito.com/login?client_id=5an7cdv75mu3dl13usljd63mj&response_type=code&scope=email+openid+phone&redirect_uri=${prefix}`
        // return  prefix
    }
    return (
        <Container  >
            <Row xs={12} className="m-5 text-center">
                <Col xs={12} >
                    <Image src={require('../assets/images/logo.png')} fluid />
                </Col>
                <Col xs={12} className="mt-5 text-center">
                    <Button onClick={(e) => handleLoginWithAzure(e)} variant="primary">Click here to login</Button>
                </Col>
            </Row>

        </Container>
    )
}
export default Login